import "../styles/home.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";

export default function Home(props) {
	const { t } = useTranslation();
	//const localizer = t;
	const [banners, setBanners] = useState([]);
	const [stores, setStores] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = function () {
		try {
			setBanners([
				{
					imageUrl: "/images/banner1.jpg?v=1",
					title: "طرح های جذاب خدمات رفاهی",
					notes: "به زودی با طرح های بسیار جذاب خدمات رفاهی ویژه تاکسی داران گرامی",
				},
				{
					imageUrl: "/images/banner2.jpg?v=1",
					title: "باشگاه خدمات رفاهی تاکسیرانی تهران",
					notes:
						"استفاده از خدمات تخفیف و اعتباری و اقساطی در مراکر طرف قرارداد باشگاه خدمات رفاهی و تاکسیرانی تهران",
				},
				{
					imageUrl: "/images/banner3.jpg?v=1",
					title: "خدمات رفاهی تاکسیرانی تهران",
					notes: "خدمات رفاهی تاکسیرانی تهران",
				},
			]);
		} catch (error) {
			alert(error.toString());
		}
	};

	const bannerTemplate = (banner) => {
		return (
			<div className="banner">
				<img src={banner.imageUrl} alt={banner.title} />
			</div>
		);
	};

	return (
		<>
			<Carousel
				value={banners}
				numVisible={1}
				numScroll={1}
				itemTemplate={bannerTemplate}
				autoplayInterval={3000}
				circular
			/>

			<div className="quick-access">
				<h2>دسترسی سریع</h2>

				<ul>
					<li>
						<a href="/#/login">
							<i className="pi pi-sign-in"></i>
							<span>عضویت</span>
							<p>ورود به باشگاه مشتریان</p>
						</a>
					</li>
					<li>
						<a href="/#/register">
							<i className="pi pi-user-edit"></i>
							<span>کمپین ها</span>
							<p>برای استفاده از امکانات بیشتر اطلاعات خود را تکمیل نمایید</p>
						</a>
					</li>
					<li>
						<a href="/#/register">
							<i className="pi pi-heart"></i>
							<span>مراکز مورد علاقه</span>
							<p>معرفی مراکر معتبر مورد علاقه</p>
						</a>
					</li>
					<li>
						<a href="/#/register">
							<i className="pi pi-wallet"></i>
							<span>مراکز اقامتی و تفریحی</span>
							<p>رزرو اماکن اقامتی و تفریحی</p>
						</a>
					</li>
				</ul>
			</div>

			<ul className="sections">
        <li>
          <a href="/#/section-accessories">
            <img src="/images/section-accessories.jpg" />
            <span>فروشگاه ها  و مراکز اختصاصی</span>
          </a>
        </li>
        <li>
          <a href="/#/section-credits">
            <img src="/images/section-credits.jpg" />
            <span>فروشگاه های خرید اعتباری و اقساطی </span>
          </a>
        </li>
        <li>
          <a href="/#/section-stores">
            <img src="/images/section-stores.jpg" />
            <span>سایر فروشگاه های طرف قرارداد</span>
          </a>
        </li>
      </ul>
		</>
	);
}
