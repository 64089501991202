import '../styles/call-back.css';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PaymentController from "../services/payment-controller";


export default function CallBack(props) {
    const { t } = useTranslation();
    const localizer = t;
    const [isLoading, setIsLoading] = useState(true);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    //
    const paymentController = new PaymentController();

    useEffect(() => {
        getData();
    }, []);

    const getData = async function () {
        try {
            if (window.isBusy === true)
                return;
            window.isBusy = true;
            setErrorMessage("")
            const queryParams = new URLSearchParams(window.location.href);

            var result = await paymentController.getPayment(queryParams.get('paymentId'));
            if (result.isSuccess === false)
                throw result.message;
            setTrackingNumber(result.value.trackingNumber);
        }
        catch (error) {
            setErrorMessage(error.toString());
        }
        window.isBusy = false;
        setIsLoading(false);
    }

    return (
        <article className="call-back">
            {
                (isLoading === false && !errorMessage) &&
                <>
                    <div className="header">{localizer("Success payment")}</div>
                    <div  >
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                            <g fill="none" stroke="#8EC343" stroke-width="2">
                                <circle cx="36" cy="36" r="35" style={{ strokeDdasharray: "240px, 240px", strokeDashoffset: "480px" }}></circle>
                                <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style={{ strokeDasharray: "50px, 50px", strokeDashoffset: "0px" }}></path>
                            </g>
                        </svg>
                        <h2>{localizer("Thanks for your shopping")}</h2>
                        <p>
                            {localizer("Your payment has been successfully completed , yous tacking number is :")}
                            <strong  > {trackingNumber}</strong>
                        </p>
                    </div>
                </>
            }
            {
                (isLoading === false && errorMessage) &&
                <div className="failed" >{errorMessage}</div>
            }
            {
                isLoading === true &&
                <>
                <h1>{localizer("Please wait...")}</h1>
                </>
            }

        </article>

    )
}