import "../styles/plate.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

export default function Plate(props) {
	const { t } = useTranslation();
	//const localizer = t;
	const [digit2, setDigit2] = useState("");
	const [digit3, setDigit3] = useState("");
	const [iranCode, setIranCode] = useState("");

	const getData = function () {
		try {
			if (!props.value || props.value.length !== 9) return;
			setDigit2(props.value.substr(0, 2));
			setDigit3(props.value.substr(3, 3));
			setIranCode(props.value.substr(7, 2));
		} catch (error) {
			alert(error.toString());
		}
	};

	const update = function (target, newValue) {
		let value = `AATBBB-CC`;
		if (target === "digit2") {
			value = value.replace("AA", newValue);
			setDigit2(newValue);
		} else if (target === "digit3") {
			value = value.replace("BBB", newValue);
			setDigit3(newValue);
		} else if (target === "iranCode") {
			value = value.replace("CC", newValue);
			setIranCode(newValue);
		}

		value = value.replace("AA", digit2);
		value = value.replace("BBB", digit3);
		value = value.replace("CC", iranCode);

		if (props.onChange) props.onChange({ value: value });
	};

	useEffect(() => {
		getData();
	}, null);

	return (
		<div className="plate">
			<ul>
				<li className="flag">
					<img src="/images/ir.png" />
					<span>I.R.</span>
					<span>IRAN</span>
				</li>
				<li className="digit-2">
					<input
						type="text"
						maxLength={2}
						value={digit2}
						onChange={(e) => update("digit2", e.target.value)}
					/>
				</li>
				<li className="letter">
					<span>TAXI</span>
					<b>ت</b>
				</li>
				<li className="digit-3">
					<input
						type="text"
						maxLength={3}
						value={digit3}
						onChange={(e) => update("digit3", e.target.value)}
					/>
				</li>
				<li className="iran">
					<span>ایران</span>
					<input
						type="text"
						maxLength={2}
						value={iranCode}
						onChange={(e) => update("iranCode", e.target.value)}
					/>
				</li>
			</ul>
		</div>
	);
}
