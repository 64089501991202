import "../styles/edit-requests.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import {  getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { InputTextarea } from "primereact/inputtextarea";
import Plate from "../components/plate.js";

export default function EditRequests() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [statusNotes, setStatusNotes] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	//
	const [requests, setRequests] = useState([]);
	const [request, setRequest] = useState({ store: {}, user: {} });
	//> useEffect
	useEffect(() => {
		getData();
	}, [searchStatus,first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			let isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				isStore = urlParams.get("isStore");
			}

			const requestsResult = await adminController.getRequests(
				isStore,
				searchStatus,
				Math.floor(first / 12) + 1,
				12
			);
			if (requestsResult.isSuccess === false) throw requestsResult.message;
			requestsResult.value.map((item) => {
				item.createdOn = new Date(item.createdOn);
			});

			let lastRowNumber = first ;
			requestsResult.value.map(item=> item.index = ++lastRowNumber);

			setRequests(requestsResult.value);
			setTotalRecords(requestsResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> changeSearchStatus
	const changeSearchStatus = function (status) {
		setSearchStatus(status);

		getData();
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> viewRequest
	const viewRequest = async function (objRequest) {
		try {
			setPageType("view");
			const requestResult = await adminController.getRequest(
				objRequest.requestId
			);
			if (requestResult.isSuccess === false) throw requestResult.message;
			if (requestResult.value.user.birthDate) {
				requestResult.value.user.age =
					new Date().getFullYear() -
					new Date(requestResult.value.user.birthDate).getFullYear();
			}
			if (requestResult.value.user.validityDate) {
				requestResult.value.user.validityDateTitle = new Date(
					requestResult.value.user.ValidityDate
				).toLocaleString("fa-IR", { dateStyle: "short" });
			}
			setRequest(requestResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateRequestStatus
	const updateRequestStatus = async function (status) {
		try {
			let errors = [];

			if (status === "Reject" && !statusNotes)
				errors.push("توضیحات رد درخواست را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "تغییر وضعیت درخواست",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateRequestResult = await adminController.updateRequestStatus(
				request.requestId,
				status,
				statusNotes
			);
			if (updateRequestResult.isSuccess === false)
				throw updateRequestResult.message;

			toast.current.show({
				severity: "success",
				summary: "تغییر وضعیت درخواست",
				detail: localizer("تغییر وضعیت درخواست با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> cancelRequest
	const cancelRequest = function () {
		setPageType("list");
	};
	//> downloadExcel
	const downloadExcel = async function () {
		try {
			setIsDownloading(true);

			let isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				isStore = urlParams.get("isStore");
			}

			const exportResult = await adminController.exportRequests(isStore,searchStatus);
			if (exportResult.isSuccess === false) throw exportResult.message;
			document
				.querySelector("#excel")
				.setAttribute(
					"href",
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
						exportResult.value
				);
			document.querySelector("#excel").click();
		} catch (error) {
			alert(error.toString());
		}
		setIsDownloading(false);
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="requests">
				{pageType === "list" && (
					<>
						<ul className="status">
							<li
								className={searchStatus === "" ? "active" : ""}
								onClick={() => changeSearchStatus("")}
							>
								همه
							</li>
							<li
								className={searchStatus === "Draft" ? "active draft" : "draft"}
								onClick={() => changeSearchStatus("Draft")}
							>
								منتظر تایید
							</li>
							<li
								className={
									searchStatus === "Accept" ? "active accept" : "accept"
								}
								onClick={() => changeSearchStatus("Accept")}
							>
								تایید شده
							</li>
							<li
								className={
									searchStatus === "Reject" ? "active reject" : "reject"
								}
								onClick={() => changeSearchStatus("Reject")}
							>
								رد شده
							</li>
						</ul>
						<div>
							<Button
								label="خروجی اکسل"
								icon={PrimeIcons.FILE_EXCEL}
								onClick={() => downloadExcel()}
								loading={isDownloading}
							/>
							<a id="excel" style={{ display: "none" }} target="_blank" />
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>کد درخواست</th>
									<th>نام شرکت</th>
									<th>نام راننده</th>
									<th>موبایل</th>
									<th>کد ملی</th>
									<th>تاریخ درخواست</th>
								</tr>
							</thead>
							<tbody>
								{requests.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => viewRequest(item)}
											>
												{item.code}
											</a>
										</td>
										<td>{item.storeName}</td>
										<td>{item.userFullName}</td>
										<td>{item.mobile}</td>
										<td>{item.nationalId}</td>
										<td>
											{item.createdOn.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "view" && (
					<div className="view-details">
						<h2>مشخصات راننده</h2>
						<ul className="user-info">
							<li>
								<label>نام راننده</label>
								<strong>{request.user.fullName}</strong>
							</li>
							<li>
								<label>موبایل</label>
								<strong>{request.user.mobile}</strong>
							</li>
							<li>
								<label>کد ملی</label>
								<strong>{request.user.nationalId}</strong>
							</li>
							<li>
								<label>سن راننده</label>
								<strong>{request.user.age}</strong>
							</li>
							<li>
								<label>جنسیت</label>
								<strong>{request.user.gender === true ? "مرد" : "زن"}</strong>
							</li>
							<li>
								<label>شماره پلاک</label>
								<div className="plate-data">
									<Plate value={request.user.plateNumber} />
								</div>
							</li>
							<li>
								<label>تاریخ اعتبار</label>
								<strong>{request.user.validityDateTitle}</strong>
							</li>
						</ul>

						<h2>مشخصات فروشگاه / مرکز ارایه خدمات</h2>
						<ul>
							<li>
								<label>نام مرکز / فروشگاه</label>
								<strong>{request.store.name}</strong>
							</li>
							<li>
								<label>تلفن</label>
								<strong>{request.store.name}</strong>
							</li>
							<li>
								<label>خدمات</label>
								<strong>{request.store.services}</strong>
							</li>
							<li>
								<label>شرح</label>
								<strong>{request.store.notest}</strong>
							</li>
							<li>
								<label>آدرس</label>
								<strong>{request.store.address}</strong>
							</li>
						</ul>
						<InputTextarea
							id="statusNotes"
							className="w-full"
							value={statusNotes}
							onChange={(e) => setStatusNotes(e.target.value)}
							placeholder="توضیحات تغییر وضعیت"
						/>

						<div className="col-12 buttons">
							<Button
								label="تایید درخواست"
								onClick={() => updateRequestStatus("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="رد درخواست"
								onClick={() => updateRequestStatus("Reject")}
								severity="danger"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelRequest()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
