import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function AminController() {
	const token = getToken();
	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};
	//> getSummary
	this.getSummary = async function (today) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetSummary?isToday=${today}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getUsers
	this.getUsers = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUsers?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> ExportUsers
	this.exportUsers = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportUsers`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUserProfile
	this.getUserProfile = async function (userId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUserProfile?userId=${userId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getRequests
	this.getRequests = async function (isStore, status, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetRequests?isStore=${isStore}&status=${status}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> exportRequests
	this.exportRequests = async function (isStore, status) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportRequests?isStore=${isStore}&status=${status}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getRequest
	this.getRequest = async function (requestId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetRequest?requestId=${requestId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateRequestStatus
	this.updateRequestStatus = async function (requestId, status, statusNotes) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateRequestStatus`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					requestId: requestId,
					status: status,
					statusNotes: statusNotes,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getStoresSummary
	this.getStoresSummary = async function (status, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetStoresSummary?status=${status}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> exportRequests
	this.exportStoresSummary = async function (status) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportStoresSummary?status=${status}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getCampaigns
	this.getCampaigns = async function (status, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetCampaigns?status=${status}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateCampaign
	this.updateCampaign = async function (
		campaignId,
		companyName,
		title,
		summary,
		notes,
		startDate,
		endDate,
		status
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateCampaign`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					campaignId: campaignId,
					companyName: companyName,
					title: title,
					summary: summary,
					notes: notes,
					startDate: startDate,
					endDate: endDate,
					status: status,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> addCampaignImage
	this.addCampaignImage = async function (campaignId, file) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("entityName", "Crm_Campaigns");
			formData.append("entityId", campaignId);
			formData.append("keyName", "Image");

			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}File/Upload`,
				headers: {
					accept: "application/json",
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				data: formData,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getStores
	this.getStores = async function (storeType, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetStores?storeType=${storeType}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateStore
	this.updateStore = async function (
		storeId,
		name,
		rowNumber,
		offPercent,
		summary,
		notes,
		dayOfWork,
		startTime,
		endTime,
		latitude,
		longitude,
		address,
		tel,
		otherTel,
		website,
		telegram,
		instagram,
		subCategoryName,
		parentCategoryName,
		stateName,
		cityName,
		area,
		storeType,
		userId,
		dailyCapacity
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateStore`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					storeId: storeId,
					name: name,
					rowNumber: rowNumber,
					offPercent: offPercent,
					summary: summary,
					notes: notes,
					dayOfWork: dayOfWork,
					startTime: startTime,
					endTime: endTime,
					latitude: latitude,
					longitude: longitude,
					address: address,
					tel: tel,
					otherTel: otherTel,
					website: website,
					telegram: telegram,
					instagram: instagram,
					subCategoryName: subCategoryName,
					parentCategoryName: parentCategoryName,
					stateName: stateName,
					cityName: cityName,
					area: area,
					storeType: storeType,
					userId: userId,
					dailyCapacity: dailyCapacity,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> addStoreImage
	this.addStoreImage = async function (storeId, file) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("entityName", "Crm_Stores");
			formData.append("entityId", storeId);
			formData.append("keyName", "Image");

			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}File/Upload`,
				headers: {
					accept: "application/json",
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				data: formData,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getTaxis
	this.getTaxis = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetTaxis?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateTaxi
	this.updateTaxi = async function (
		taxiId,
		fullName,
		nationalId,
		fatherName,
		mobile,
		birthDate,
		gender,
		serialNumber,
		engineNumber,
		chassisNumber,
		productionYear,
		color,
		fuelType,
		carType,
		plateNumber
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateTaxi`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					taxiId: taxiId,
					fullName: fullName,
					nationalId: nationalId,
					fatherName: fatherName,
					mobile: mobile,
					birthDate: birthDate,
					gender: gender,
					serialNumber: serialNumber,
					engineNumber: engineNumber,
					chassisNumber: chassisNumber,
					productionYear: productionYear,
					color: color,
					fuelType: fuelType,
					carType: carType,
					plateNumber: plateNumber,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
}
